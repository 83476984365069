import styled from "styled-components";
import { colors } from "../utils/Colors";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import linkedinSvg from "../utils/linkedin.svg";
import gitSvg from "../utils/githubSvg.svg";
import { ReactSVG } from "react-svg";
import { useState } from "react";

const NavBar = () => {
  const [show, setShow] = useState(false);

  return (
    <MainContainer collapseOnSelect expand="lg" className="bg-body-tertiary">
      <ContainerNav className="m-0 p-0">
        <Navbar.Brand>
          <p
            style={{
              cursor: "default",
              color: colors.primary200,
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            <span style={{ color: colors.primary100 }}>{`<`}</span>/F
            <span style={{ color: colors.primary100 }}>{`>`}</span>
          </p>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          style={{ border: `2px solid ${colors.primary100}` }}
        />
        <NavHamburgerContainer id="responsive-navbar-nav" restoreFocus={false}>
          <ContainerLinkIcon>
            <Nav className="me-auto w-100">
              <ContainerLink className="d-flex w-100 justify-content-center align-items-center">
                <Link
                  style={{ margin: "0 1rem 0 0" }}
                  href="#about"
                  active={false}
                >
                  About
                </Link>
                <Link href="#skills" active={false}>
                  Skills
                </Link>
                <Link href="#projects " active={false}>
                  Projects
                </Link>
                <Link href="#contact" active={false}>
                  Contact
                </Link>
              </ContainerLink>
            </Nav>
            <ContainerIcon>
              <Nav.Link
                href="https://linkedin.com/in/fabien-developer"
                target="_blank"
              >
                <LinkedinIcon src={linkedinSvg} />
              </Nav.Link>
              <Nav.Link href="https://github.com/FabienD0" target="_blank">
                <GitIcon src={gitSvg} />
              </Nav.Link>
            </ContainerIcon>
          </ContainerLinkIcon>
        </NavHamburgerContainer>
      </ContainerNav>
    </MainContainer>
  );
};

export default NavBar;

const MainContainer = styled(Navbar)`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  height: 4rem;

  background-color: ${colors.white} !important;
`;

const ContainerNav = styled(Container)`
  width: 70%;

  @media (max-width: 1150px) {
    width: 80%;
  }
`;

const ContainerLink = styled.div`
  @media (max-width: 992px) {
    justify-content: baseline !important;
    align-items: baseline !important;
    flex-direction: column;
    gap: 2rem;
  }
`;

const Link = styled(Nav.Link)`
  all: unset;
  color: hsla(197, 10%, 50%, 1);
  font-size: 24px;
  font-weight: 700;
  transition: color 0.3s;

  &:hover {
    cursor: pointer;
    color: ${colors.primary};
  }

  &::before,
  &::after {
    display: inline-block;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
    -moz-transition: -moz-transform 0.3s, opacity 0.2s;
    transition: transform 0.3s, opacity 0.2s;
  }

  &::before {
    margin-right: 2px;
    content: "<";
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    transform: translateX(20px);
  }

  &::after {
    margin-left: 2px;
    content: ">";
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  &:hover::before,
  &:hover::after,
  &:focus::before,
  &:focus::after {
    opacity: 1;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
  }

  @media (max-width: 992px) {
    color: ${colors.primary400};
    font-size: 34px;
    margin: 0 1rem;
  }

  @media (max-width: 550px) {
    font-size: 24px;
  }
`;

const LinkedinIcon = styled(ReactSVG)`
  width: 42px;
  height: auto;
  transition: all 0.3s;
  fill: hsla(197, 10%, 50%, 1);

  &:hover {
    fill: ${colors.primary200};
  }

  @media (max-width: 992px) {
    fill: ${colors.primary300};
  }
`;

const GitIcon = styled(ReactSVG)`
  width: 42px;
  height: auto;
  transition: all 0.3s;
  fill: hsla(197, 10%, 50%, 1);

  &:hover {
    fill: ${colors.primary200};
  }

  @media (max-width: 992px) {
    fill: ${colors.primary300};
  }
`;

const NavHamburgerContainer = styled(Navbar.Offcanvas)`
  display: flex;
  @media (max-width: 992px) {
    height: 100%;
    padding: 1rem;
    background-color: ${colors.primary100};
    border-radius: 0 55px 55px 0;
    padding-top: 4rem;
  }
  @media (max-width: 550px) {
    width: 15rem !important;
  }
`;

const ContainerIcon = styled(Nav)`
  @media (max-width: 992px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 4rem;
  }
`;

const ContainerLinkIcon = styled.div`
  display: flex;
  @media (max-width: 992px) {
    display: block;
  }
`;
