import styled from "styled-components";
import { colors } from "../utils/Colors";
import NavBar from "../Components/NavBar";
import { useEffect, useRef } from "react";

import { motion, useAnimation, useInView } from "framer-motion";

const Hero = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  /* Go to Contact section */
  const handleHire = () => {
    let contact = document.getElementById("contact");
    contact.scrollIntoView();
  };

  return (
    <HeroMainContainer>
      <DotSide src="/dotSideHeroPage.svg" alt="Hero Logo" />
      <NavBar />
      <ContainerHero ref={ref}>
        <Container>
          <motion.img
            src="/HeroArrow.svg"
            alt="Arrown Down"
            style={{
              position: "absolute",
              bottom: "-30px",
            }}
            variants={{
              hidden: { opacity: 0, y: 150 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
          />
          <ContainerLeft>
            <motion.div
              variants={{
                hidden: { opacity: 0, x: -150 },
                visible: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{
                duration: 0.5,
                delay: 0.25,
              }}
            >
              <ContainerText>
                <H1 className="display-3">
                  Hello World, <br />
                  I'm{" "}
                  <span style={{ position: "relative", zIndex: 2 }}>
                    <BrushH1 src="/brushLogo.svg" alt="Brush on Fabien" />
                    Fabien
                  </span>
                </H1>
                <H2>I Develop And Design Full-Stack Applications.</H2>
                <ButtonHire onClick={() => handleHire()}>Hire Me</ButtonHire>
              </ContainerText>
            </motion.div>
          </ContainerLeft>
          <ContainerRight>
            <motion.div
              variants={{
                hidden: { opacity: 0, x: 150 },
                visible: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{
                duration: 0.5,
                delay: 0.25,
              }}
            >
              <div style={{ position: "relative" }}>
                <HeroLogo
                  className="container m-0 p-0"
                  src="/heroLogo.png"
                  alt="Hero Logo"
                />
                <DotHeroLogo
                  className="position-absolute"
                  src="/dotHeroLogo.svg"
                  alt="Hero Logo"
                />
              </div>
            </motion.div>
          </ContainerRight>
        </Container>
      </ContainerHero>
      <HeroWave src="/HeroWave.svg" alt="Wave Bottom" />
    </HeroMainContainer>
  );
};

export default Hero;

const HeroMainContainer = styled.main`
  position: relative;
  background-color: ${colors.white};
  height: 100%;

  min-height: 50rem;
`;

const DotSide = styled.img`
  position: absolute;
  top: 150px;

  @media (max-width: 880px) {
    top: auto;
    bottom: 100px;
    left: -25px;
  }
`;

const ContainerHero = styled.div`
  display: flex;
  justify-content: center;

  min-height: 35rem;

  @media (max-width: 880px) {
    height: 80%;
  }

  @media (max-width: 500px) {
    height: 80%;
  }
`;

const Container = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  margin: 0 5rem;

  width: 70%;

  @media (max-width: 1150px) {
    width: 80%;
  }
  @media (max-width: 880px) {
    flex-direction: column;
  }
  @media (max-width: 500px) {
    margin: 0;
    width: 100%;
  }
`;

const ContainerLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 880px) {
    width: 100%;
    align-items: center;
    flex: 0;
    padding-bottom: 2rem;
  }
`;

const ContainerRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;

  @media (max-width: 880px) {
    width: 100%;
    justify-content: center;
    flex: 0;
    margin-bottom: 5rem;
  }
`;

const ContainerText = styled.div`
  justify-content: center;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const H1 = styled.h1`
  font-weight: 700;
  /* font-size: 4vw; */
  line-height: normal;
  color: ${colors.primary400};

  @media (max-width: 500px) {
    font-size: 48px;
  }
`;

const BrushH1 = styled.img`
  position: absolute;
  z-index: -1;
  width: 150%;
  left: -20px;
  top: 10px;

  @media (max-width: 600px) {
    left: -10px;
    width: 140%;
  }

  @media (max-width: 250px) {
    display: none;
  }
`;

const H2 = styled.h2`
  color: #73858c;
  font-family: "Open Sans";
  margin: 1rem 0;
  font-size: 1.5rem;

  @media (max-width: 1446px) {
    font-size: 1.2rem;
  }
  @media (max-width: 1160px) {
    font-size: 1rem;
  }
`;

const ButtonHire = styled.button`
  all: unset;
  text-align: center;
  border-radius: 15px;
  color: ${colors.white};
  font-size: 24px;
  font-weight: 700;
  margin-top: 1rem;
  background: ${colors.primary};
  border: 2px solid transparent;
  width: 11rem;
  height: 3.5rem;

  transition: all 0.3s;

  &:hover {
    background-color: transparent;
    border: 2px solid ${colors.primary};
    color: ${colors.primary};
  }

  @media (max-width: 880px) {
    display: none;
  }
`;

const HeroWave = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 880px) {
    position: absolute;
    bottom: 0;
  }

  @media (max-width: 1268px) {
    position: absolute;
    bottom: 0;
  }
`;

const HeroLogo = styled.img`
  position: relative;
  z-index: 20;

  @media (max-width: 880px) {
    height: 20rem;
    width: auto;
  }

  @media (max-width: 880px) {
    height: 15rem;
    width: auto;
  }

  @media (max-width: 600px) {
    height: 10rem;
    width: auto;
  }

  @media (max-width: 300px) {
    height: 5rem;
    width: auto;
  }
`;

const DotHeroLogo = styled.img`
  right: 5px;
  top: -10px;

  @media (max-width: 600px) {
    width: 50%;
    right: -10px;
    top: -10px;
  }
`;
