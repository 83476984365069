import styled from "styled-components";
import { colors } from "../utils/Colors";
import {
  AiOutlinePlus,
  AiOutlineMinus,
  AiFillGithub,
  AiOutlinePlayCircle,
  AiFillAndroid,
  AiFillApple,
  AiFillStar,
} from "react-icons/ai";
import { FiExternalLink } from "react-icons/fi";
import { useState } from "react";

const MobileProjects = () => {
  const [selected, setSelected] = useState("");

  return (
    <Container>
      {/* BEATMI */}

      <ContainerCard select={selected}>
        <ContainerTitle>
          <h1>
            BeatMi{" "}
            <span>
              <AiFillStar color={colors.secondary} />
            </span>
          </h1>
          <ButtonSelected
            onClick={() =>
              selected === "beatmi" ? setSelected("") : setSelected("beatmi")
            }
          >
            {selected === "beatmi" ? (
              <AiOutlineMinus color={colors.primary200} />
            ) : (
              <AiOutlinePlus color={colors.primary200} />
            )}
          </ButtonSelected>
        </ContainerTitle>
        {selected === "beatmi" && (
          <ContainerMainCard>
            {" "}
            <img src="bannerBeatMi.png" alt="BeatMi Card" />
            <p className="tech">JavaScript,React,Express,MongoDB</p>
            <p className="description">
              Full-Stack music website that offers a unique and engaging
              platform to create, share and interact with beats.
            </p>
            <ContainerButtonCard>
              <a
                href="https://www.beatmi.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiExternalLink size={42} />
              </a>
              <a
                href="https://github.com/FabienD0/FinalProject-BeatMi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub size={42} />
              </a>
              <a
                href="https://vimeo.com/manage/videos/822198682"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlinePlayCircle size={42} />
              </a>
            </ContainerButtonCard>
          </ContainerMainCard>
        )}
      </ContainerCard>
      {/* ECOMMERCE */}
      <ContainerCard select={selected}>
        <ContainerTitle>
          <h1>
            E-Commerce{" "}
            <span>
              <AiFillStar color={colors.secondary} />
            </span>
          </h1>
          <ButtonSelected
            onClick={() =>
              selected === "ecommerce"
                ? setSelected("")
                : setSelected("ecommerce")
            }
          >
            {selected === "ecommerce" ? (
              <AiOutlineMinus color={colors.primary200} />
            ) : (
              <AiOutlinePlus color={colors.primary200} />
            )}
          </ButtonSelected>
        </ContainerTitle>
        {selected === "ecommerce" && (
          <ContainerMainCard>
            {" "}
            <img src="bannerEcommerce.png" alt="Ecommerce Card" />
            <p className="tech">TypeScript,Vite,Express,SQL</p>
            <p className="description">
              E-Commerce project with Vite as frontend and Node.js as backend.
              Used Typescript, Bootstrap, Redux and SQL.
            </p>
            <ContainerButtonCard>
              <a
                href="https://e-commerce-ts.onrender.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiExternalLink size={42} />
              </a>
              <a
                href="https://github.com/FabienD0/Ecommerce"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub size={42} />
              </a>
            </ContainerButtonCard>
          </ContainerMainCard>
        )}
      </ContainerCard>
      {/* VINGANE */}
      <ContainerCard select={selected}>
        <ContainerTitle>
          <h1>
            Vingane{" "}
            <span>
              <AiFillStar color={colors.secondary} />
            </span>
          </h1>
          <ButtonSelected
            onClick={() =>
              selected === "vingane" ? setSelected("") : setSelected("vingane")
            }
          >
            {selected === "vingane" ? (
              <AiOutlineMinus color={colors.primary200} />
            ) : (
              <AiOutlinePlus color={colors.primary200} />
            )}
          </ButtonSelected>
        </ContainerTitle>
        {selected === "vingane" && (
          <ContainerMainCard>
            {" "}
            <img src="bannerVingane.png" alt="Vingane Card" />
            <p className="tech">JavaScript,React Native,Expo</p>
            <p className="description">
              Mobile app that gathers all vegan wines available at SAQ.
              Published and have hundreds of downloads with active users.
            </p>
            <ContainerButtonCard>
              <a
                href="https://play.google.com/store/apps/details?id=com.fabiend0.Vingane"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillAndroid size={42} />
              </a>
              <a
                href="https://apps.apple.com/ca/app/vingane/id6450844596?platform=iphone"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillApple size={42} />
              </a>
            </ContainerButtonCard>
          </ContainerMainCard>
        )}
      </ContainerCard>
      {/* TODO */}
      <ContainerCard select={selected}>
        <ContainerTitle>
          <h1>To-Do</h1>
          <ButtonSelected
            onClick={() =>
              selected === "todo" ? setSelected("") : setSelected("todo")
            }
          >
            {selected === "todo" ? (
              <AiOutlineMinus color={colors.primary200} />
            ) : (
              <AiOutlinePlus color={colors.primary200} />
            )}
          </ButtonSelected>
        </ContainerTitle>
        {selected === "todo" && (
          <ContainerMainCard>
            {" "}
            <img src="bannerTodo.png" alt="To-Do Card" />
            <p className="tech">TypeScript,Next.js,MongoDB</p>
            <p className="description">
              In addition to being my first full-stack project, this was also my
              first time using Typescript & Next.js.
            </p>
            <ContainerButtonCard>
              <a
                href="https://to-do-list-fabdev.vercel.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiExternalLink size={42} />
              </a>
              <a
                href="https://github.com/FabienD0/To-Do-List"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub size={42} />
              </a>
            </ContainerButtonCard>
          </ContainerMainCard>
        )}
      </ContainerCard>
      {/* BUDGET */}
      <ContainerCard select={selected}>
        <ContainerTitle>
          <h1>Budget</h1>
          <ButtonSelected
            onClick={() =>
              selected === "budget" ? setSelected("") : setSelected("budget")
            }
          >
            {selected === "budget" ? (
              <AiOutlineMinus color={colors.primary200} />
            ) : (
              <AiOutlinePlus color={colors.primary200} />
            )}
          </ButtonSelected>
        </ContainerTitle>
        {selected === "budget" && (
          <ContainerMainCard>
            {" "}
            <img src="bannerBudget.png" alt="Card Budget" />
            <p className="tech">JavaScript,React Native,Expo</p>
            <p className="description">
              Couldn't find a basic application that met my needs. I decided to
              learn React Native to create the application I was seeking
            </p>
            <ContainerButtonCard>
              <a
                href="https://github.com/FabienD0/Budget-MobileApp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub size={42} />
              </a>
            </ContainerButtonCard>
          </ContainerMainCard>
        )}
      </ContainerCard>
      {/* CRITER */}
      <ContainerCard select={selected}>
        <ContainerTitle>
          <h1>Critter</h1>
          <ButtonSelected
            onClick={() =>
              selected === "critter" ? setSelected("") : setSelected("critter")
            }
          >
            {selected === "critter" ? (
              <AiOutlineMinus color={colors.primary200} />
            ) : (
              <AiOutlinePlus color={colors.primary200} />
            )}
          </ButtonSelected>
        </ContainerTitle>
        {selected === "critter" && (
          <ContainerMainCard>
            {" "}
            <img src="bannerCritter.png" alt="Critter Card" />
            <p className="tech">JavaScript,React</p>
            <p className="description">
              My first react project, built the frontend of a Twitter clone
              named "Critter" a social network for cats.
            </p>
            <ContainerButtonCard>
              <a
                href="https://vimeo.com/794596119"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlinePlayCircle size={42} />
              </a>
              <a
                href="https://github.com/FabienD0/Twitter-Clone"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub size={42} />
              </a>
            </ContainerButtonCard>
          </ContainerMainCard>
        )}
      </ContainerCard>
      {/* POKEMON WORLD */}
      <ContainerCard select={selected}>
        <ContainerTitle>
          <h1>Pokemon World</h1>
          <ButtonSelected
            onClick={() =>
              selected === "pokemon" ? setSelected("") : setSelected("pokemon")
            }
          >
            {selected === "pokemon" ? (
              <AiOutlineMinus color={colors.primary200} />
            ) : (
              <AiOutlinePlus color={colors.primary200} />
            )}
          </ButtonSelected>
        </ContainerTitle>
        {selected === "pokemon" && (
          <ContainerMainCard>
            {" "}
            <img src="bannerPokemon.png" alt="Pokemon World Card" />
            <p className="tech">Html,CSS,JavaScript</p>
            <p className="description">
              This is my first project. Using keyboard keys, you must catch and
              avoid multiple Pokemon to increase your score.
            </p>
            <ContainerButtonCard>
              <a
                href="https://pokemonworldgame.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiExternalLink size={42} />
              </a>
              <a
                href="https://github.com/FabienD0/Pokemon-World"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillGithub size={42} />
              </a>
            </ContainerButtonCard>
          </ContainerMainCard>
        )}
      </ContainerCard>
    </Container>
  );
};

export default MobileProjects;

const Container = styled.div`
  display: none;
  z-index: 22;

  @media (max-width: 850px) {
    display: block;
  }
`;

const ContainerCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${colors.white};
  transition: all 0.3s;
  height: ${(props) => (props.select.length !== 0 ? "fit-content" : "5rem")};
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* border: 2px solid ${colors.primary200}; */
  background: #fff;
  padding: 0.5rem;
  margin: 1rem 0;
`;

const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  h1 {
    font-family: "Open Sans";
    font-size: 24px;
    font-weight: 900;
    margin-left: 0.7rem;
    color: ${colors.primary300};
  }
`;

const ButtonSelected = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  font-size: 28px;
  /* background-color: ${colors.primary};
  border-radius: 50%;
  color: ${colors.primary400};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.3s; */

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
`;

const ContainerMainCard = styled.div`
  img {
    width: 100%;
  }

  .tech {
    font-style: italic;
    color: ${colors.secondary300};
    text-align: center;
  }

  .description {
    margin-top: 1rem;
    font-weight: bold;
  }
`;

const ContainerButtonCard = styled.div`
  margin-top: 1rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: ${colors.primary200};

  a {
    all: unset;
    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`;
