import GlobalStyle from "./globalStyles";
import styled from "styled-components";
import Hero from "./Pages/Hero";
import About from "./Pages/About";
import Skills from "./Pages/Skills";
import Projects from "./Pages/Projects";
import Contact from "./Pages/Contact";
import Footer from "./Components/Footer";

/* TO DO 
CAROUSEL DISAPEAR ??
*/

function App() {
  return (
    <>
      <GlobalStyle />
      <Container>
        <Hero />
        <About />
        <Skills />
        <Projects />
        <Contact />
        <Footer />
      </Container>
    </>
  );
}

export default App;

const Container = styled.main`
  height: 90vh;

  @media (max-width: 880px) {
    min-height: 45rem;
  }
`;
