export const colors = {
  white: "#F2F9FB",
  primary: "#00BFFF",
  primary100: "#78CEEA",
  primary200: "#156A87",
  primary300: "#0F2C36",
  primary400: "#0D1417",

  secondary: "#FFB700",
  secondary100: "#EED494",
  secondary200: "#F0C149",
  secondary300: "#B6860F",
  secondary400: "#826315",
  secondary500: "#826315",

  tertiary: "#00BF82",
  tertiary100: "#C8EEE1",
  tertiary200: "#82E1C3",
  tertiary300: "#41E0AD",
  tertiary400: "#12D99A",
  tertiary500: "#0B8860",
  tertiary600: "#106247",
  tertiary700: "#114635",
};
