import styled from "styled-components";
import { colors } from "../utils/Colors";

import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

const About = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <MainContainer id="about">
      <WaveBotContainer src="/AboutWave.svg" alt="Wave About" />
      <Container>
        <DivMediaQueries>
          <ContainerTitle>
            <H1>My Superpowers</H1>
            <LineTitle></LineTitle>
          </ContainerTitle>
        </DivMediaQueries>
        <ContainerSectionMid ref={ref}>
          <ContainerLeft>
            <ContainerAllCard>
              <ContainerCard
                variants={{
                  hidden: { opacity: 0, x: -150 },
                  visible: { opacity: 1, x: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{
                  duration: 0.5,
                  delay: 0.25,
                }}
              >
                <div>
                  <IconCard src="AboutDesignIcon.png" alt="Design Icon" />
                </div>
                <ContainerCardText>
                  <CardTitle>Design</CardTitle>
                  <CardTitleIcon>
                    <Title2>Design</Title2>
                    <Icon2 src="/DesignTrans.png" alt="Design Icon"></Icon2>
                  </CardTitleIcon>
                  <CardDescription>
                    I enjoy applying my knowledge of UI/UX design to create
                    aesthetically pleasing and user-friendly templates.
                  </CardDescription>
                </ContainerCardText>
              </ContainerCard>
              <ContainerCard
                variants={{
                  hidden: { opacity: 0, x: -150 },
                  visible: { opacity: 1, x: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{
                  duration: 0.5,
                  delay: 0.45,
                }}
              >
                <div>
                  <IconCard src="AboutWebIcon.png" alt="Web Icon" />
                </div>
                <ContainerCardText>
                  <CardTitle>Web Development</CardTitle>
                  <CardTitleIcon>
                    <Title2>Web</Title2>
                    <Icon2 src="/WebTrans.png" alt="Web Icon"></Icon2>
                  </CardTitleIcon>
                  <CardDescription>
                    Frontend or backend, I actively engage with new technologies
                    to craft responsive websites and refine my skills.
                  </CardDescription>
                </ContainerCardText>
              </ContainerCard>
              <ContainerCard
                variants={{
                  hidden: { opacity: 0, x: -150 },
                  visible: { opacity: 1, x: 0 },
                }}
                initial="hidden"
                animate={mainControls}
                transition={{
                  duration: 0.5,
                  delay: 0.55,
                }}
              >
                <div>
                  <IconCard src="AboutMobileIcon.png" alt="Mobile Icon" />
                </div>
                <ContainerCardText>
                  <CardTitle>Mobile Development</CardTitle>
                  <CardTitleIcon>
                    <Title2>Mobile</Title2>
                    <Icon2 src="/MobileTrans.png" alt="Mobile Icon"></Icon2>
                  </CardTitleIcon>
                  <CardDescription>
                    Working with Android and iOS. I am employing my skills to
                    solve your problems right in the palm of your hands.
                  </CardDescription>
                </ContainerCardText>
              </ContainerCard>
            </ContainerAllCard>
          </ContainerLeft>
          <ContainerRight ref={ref}>
            <motion.div
              variants={{
                hidden: { opacity: 0, x: 150 },
                visible: { opacity: 1, x: 0 },
              }}
              initial="hidden"
              animate={mainControls}
              transition={{
                duration: 0.5,
                delay: 0.25,
              }}
            >
              <div
                style={{
                  position: "relative",
                  zIndex: 10,
                }}
              >
                <BlobMe src="/BlobAbout.png" alt="Blob About" />
                <BlobDot
                  src="DotBlob.svg"
                  alt="Dot Blob"
                  style={{ position: "absolute", right: 0, zIndex: -1 }}
                />
                <BlobWave
                  src="WaveBlob.svg"
                  alt="Waves Blob"
                  style={{
                    position: "absolute",
                    left: "-30px",
                    top: "40%",
                    zIndex: -1,
                  }}
                />
              </div>
            </motion.div>
          </ContainerRight>
        </ContainerSectionMid>
      </Container>
    </MainContainer>
  );
};

export default About;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${colors.primary100};
  height: 100%;

  overflow: auto;

  @media only screen and (max-height: 1907px) and (pointer: coarse) {
    height: auto;
  }
`;

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  width: 70%;

  @media (max-width: 1150px) {
    width: 80%;
  }
`;

const ContainerTitle = styled.div`
  margin-top: 5rem;
  width: fit-content;
  position: relative;
  z-index: 2;

  @media (max-width: 880px) {
    margin-bottom: 2rem;
  }
`;

const ContainerSectionMid = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  @media (max-width: 1150px) {
    gap: 1.5rem;
  }
  @media (max-width: 880px) {
    gap: 0;
    flex-direction: column;
  }
`;

const ContainerLeft = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 80%;
`;

const ContainerRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  height: 80%;
`;

const H1 = styled.h1`
  color: #0f2c36;
  font-size: 42px;
  font-weight: 700;

  @media (max-width: 450px) {
    font-size: 34px;
  }
`;

const LineTitle = styled.div`
  position: absolute;
  bottom: 0;
  opacity: 65%;
  height: 20px;
  width: 105%;
  left: 50%;
  transform: translateX(-50%);

  background-color: ${colors.secondary};
  border-radius: 15px;

  z-index: -1;
`;

const ContainerAllCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const ContainerCard = styled(motion.div)`
  display: flex;
  gap: 2rem;

  @media (max-width: 600px) {
    flex-direction: column;
    /* align-items: center; */
    gap: 0;
  }
`;

const IconCard = styled.img`
  width: auto;

  @media (max-width: 600px) {
    display: none;
    width: 3rem;
    margin: 1rem 0;
  }
`;

const ContainerCardText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 600px) {
    margin-bottom: 1rem;
  }
  @media only screen and (max-height: 1907px) and (pointer: coarse) {
    margin-bottom: 1rem;
  }
`;

const CardTitle = styled.h2`
  color: ${colors.primary300};
  font-size: 32px;
  font-weight: 700;

  @media (max-width: 1150px) {
    font-size: 24px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const CardDescription = styled.p`
  color: hsl(195, 57%, 15%);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  font-family: "Open Sans", sans-serif;

  @media (max-width: 1150px) {
    font-size: 16px;
  }
`;

const BlobMe = styled.img`
  z-index: 20;
  @media (max-width: 1150px) {
    width: 100%;
    height: auto;
  }
  @media (max-width: 880px) {
    height: 20rem;
    width: auto;
  }

  @media (max-width: 600px) {
    height: 12rem;
    width: auto;
  }

  @media (max-width: 300px) {
    height: 10rem;
    width: auto;
  }
`;

const BlobDot = styled.img`
  @media (max-width: 600px) {
    height: 5rem;
    width: auto;
  }
`;

const BlobWave = styled.img``;

const WaveBotContainer = styled.img`
  position: absolute;
  bottom: 100px;
  right: 0;

  @media (max-width: 880px) {
    bottom: 100px;
    width: 25%;
  }
`;

const DivMediaQueries = styled.div`
  display: flex;
  justify-content: start;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

///Card Title Web Queries
const CardTitleIcon = styled.div`
  display: none;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 600px) {
    display: flex;
    width: 100%;
    /* justify-content: center; */
    align-items: center;
    /* border: 2px solid red; */
  }
`;

const Icon2 = styled.img`
  width: 2rem;
`;

const Title2 = styled.h2`
  color: ${colors.primary300};
  font-size: 26px;
  font-weight: 700;
`;
