import styled from "styled-components";
import { colors } from "../utils/Colors";
import arrowUp from "../utils/arrowUp.svg";
import { ReactSVG } from "react-svg";

const Contact = () => {
  /* Navigate to Top */
  const goToTop = () => {
    window.scrollTo(0, 0);
  };

  /* Button Send email */
  const sendMail = () => {
    window.open("mailto:fabien.denommee@gmail.com");
  };

  /* Open CV */
  const openResume = () => {
    window.open("/resume.pdf", "_blank", "fullscreen=yes");
  };

  return (
    <MainContainer id="contact">
      <Container>
        <H1>Let's work together</H1>
        <ContainerButton>
          <ButtonContact onClick={() => sendMail()}>Email</ButtonContact>
          <ButtonContact onClick={() => openResume()}>Resume</ButtonContact>
        </ContainerButton>
      </Container>
      <ArrowUpSvg src={arrowUp} onClick={() => goToTop()} />
    </MainContainer>
  );
};

export default Contact;

const MainContainer = styled.div`
  position: relative;
  display: flex;
  background-color: ${colors.white};
  justify-content: center;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-height: 35rem;

  z-index: -1;

  /* background: linear-gradient(
    to bottom right,
    ${colors.primary100} 50%,
    ${colors.white} 50%
  ); */

  background-image: url("backgroundContactDark.svg");
  background-size: cover;

  @media only screen and (max-height: 1907px) and (pointer: coarse) {
    height: auto;
  }
`;

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 123;
  margin-top: 12rem;

  gap: 2rem;

  width: 70%;

  @media (max-width: 1150px) {
    width: 80%;
  }
`;

const H1 = styled.h1`
  color: ${colors.primary100};
  font-size: 52px;
  font-weight: 700;
  z-index: 123;
  margin-top: 3rem;
  text-align: center;

  @media (max-width: 880px) {
    margin-top: 0;
  }

  @media (max-width: 610px) {
    font-size: 40px;
  }

  @media (max-width: 450px) {
    font-size: 28px;
  }
`;

const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 2rem;

  @media (max-width: 880px) {
    margin-top: 4rem;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    margin-top: 1rem;
  }
`;

const ButtonContact = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Open Sans";
  width: 10rem;
  height: 3rem;
  font-size: 22px;
  font-weight: bold;
  border-radius: 15px;
  border: 2px solid ${colors.white};
  color: ${colors.white};
  transition: all 0.3s;

  &:hover {
    background-color: ${colors.primary};
    border: 2px solid transparent;
    color: ${colors.primary400};
  }

  @media (max-width: 450px) {
    width: 10rem;
  }
`;

const ArrowUpSvg = styled(ReactSVG)`
  position: absolute;
  fill: ${colors.primary100};
  margin: 1rem;
  width: 3.5rem;
  height: 3.5rem;
  transition: all 0.3s;
  animation: MoveUpDown 2s linear infinite;

  &:hover {
    fill: ${colors.primary300};
    opacity: 0.5;
    cursor: pointer;
  }

  @keyframes MoveUpDown {
    0%,
    100% {
      bottom: 0;
      fill: ${colors.primary200};
    }
    50% {
      bottom: 10px;
      fill: ${colors.primary};
    }
  }
`;
