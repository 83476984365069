import styled from "styled-components";
import { colors } from "../utils/Colors";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

const Skills = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <MainContainer id="skills">
      <Container>
        <ContainerTitle>
          <H1>Skills</H1>
          <LineTitle></LineTitle>
        </ContainerTitle>
        <ContainerAllCard>
          <DotSkillsBottom
            src="/dotSkills.svg"
            alt="Dot Pattern"
            style={{ bottom: "-10px", left: "-40px" }}
          />
          <ContainerLanguages
            ref={ref}
            variants={{
              hidden: { opacity: 0, x: -150 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
          >
            <Icon src="/IconLanguages.png" alt="Icon Languages"></Icon>
            <Title colortitle={colors.secondary}>Languages</Title>
            <ContainerText>
              <div>
                <Text>
                  <Dot colortitle="#fd9" />
                  JavaScript
                </Text>
                <Text>
                  <Dot colortitle="#fd9" />
                  TypeScript
                </Text>
                <Text>
                  <Dot colortitle="#fd9" />
                  HTML
                </Text>
              </div>
              <div>
                <Text>
                  <Dot colortitle="#fd9" />
                  CSS
                </Text>
                <Text>
                  <Dot colortitle="#fd9" />
                  Java
                </Text>
                <Text>
                  <Dot colortitle="#fd9" />
                  SQL
                </Text>
              </div>
            </ContainerText>
          </ContainerLanguages>
          <ContainerTech
            variants={{
              hidden: { opacity: 0, y: -150 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
          >
            <Icon src="/IconTech.png" alt="Icon Tech"></Icon>
            <Title colortitle={colors.tertiary}>Technologies</Title>
            <ContainerText>
              <div>
                <Text>
                  <Dot colortitle="#99FFDF" />
                  React
                </Text>
                <Text>
                  <Dot colortitle="#99FFDF" />
                  React Native
                </Text>
                <Text>
                  <Dot colortitle="#99FFDF" />
                  MongoDB
                </Text>
                <Text>
                  <Dot colortitle="#99FFDF" />
                  Node.js
                </Text>
                <Text>
                  <Dot colortitle="#99FFDF" />
                  Bootstrap
                </Text>
              </div>
              <div>
                <Text>
                  <Dot colortitle="#99FFDF" />
                  Express
                </Text>
                <Text>
                  <Dot colortitle="#99FFDF" />
                  Redux
                </Text>
                <Text>
                  <Dot colortitle="#99FFDF" />
                  Expo
                </Text>
                <Text>
                  <Dot colortitle="#99FFDF" />
                  MySQL
                </Text>
                <Text>
                  <Dot colortitle="#99FFDF" />
                  Next.js
                </Text>
              </div>
            </ContainerText>
          </ContainerTech>
          <ContainerTools
            variants={{
              hidden: { opacity: 0, x: 150 },
              visible: { opacity: 1, x: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
          >
            <Icon src="/IconTools.png" alt="Icon Tools"></Icon>
            <Title colortitle={colors.primary200}>Tools</Title>
            <ContainerText>
              <div>
                <Text>
                  <Dot colortitle="#A7DEF1" />
                  Github
                </Text>
                <Text>
                  <Dot colortitle="#A7DEF1" />
                  Photoshop
                </Text>
                <Text>
                  <Dot colortitle="#A7DEF1" />
                  VS Code
                </Text>
              </div>
              <div>
                <Text>
                  <Dot colortitle="#A7DEF1" />
                  AWS
                </Text>
                <Text>
                  <Dot colortitle="#A7DEF1" />
                  Figma
                </Text>
                <Text>
                  <Dot colortitle="#A7DEF1" />
                  Git
                </Text>
              </div>
            </ContainerText>
          </ContainerTools>
          <DotSkills
            src="/dotSkills.svg"
            alt="Dot Pattern"
            style={{ top: "-10px", right: "-40px" }}
          />
          <DotSkillsRow src="/dotSkills.svg" alt="Dot Pattern" />
        </ContainerAllCard>
      </Container>
    </MainContainer>
  );
};

export default Skills;

const MainContainer = styled.div`
  position: relative;
  display: flex;
  background-color: ${colors.white};
  justify-content: center;
  align-items: center;
  min-height: 45rem;
  @media only screen and (max-height: 1907px) and (pointer: coarse) {
    height: auto;
  }
  @media (max-width: 880px) {
    min-height: 90rem;
  }

  overflow-x: hidden;
`;

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 70%;

  @media (max-width: 1150px) {
    width: 80%;
  }
`;

const ContainerTitle = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
  z-index: 2;

  @media (max-width: 880px) {
    margin: 2rem 0;
  }
`;

const H1 = styled.h1`
  color: #0f2c36;
  font-size: 42px;
  font-weight: 700;

  @media (max-width: 450px) {
    font-size: 34px;
  }
`;

const LineTitle = styled.div`
  position: absolute;
  bottom: 0;
  opacity: 65%;
  height: 20px;
  width: 105%;
  left: 50%;
  transform: translateX(-50%);

  background-color: ${colors.secondary};
  border-radius: 15px;

  z-index: -1;
`;

const ContainerAllCard = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30rem;
  margin-top: 2rem;

  @media (max-width: 880px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const ContainerLanguages = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border-top: 5px solid ${colors.secondary};
  background: #fff;
  /* width: 341px; */
  width: 30%;
  height: 354px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 20;

  padding: 1rem 0;
  gap: 1rem;

  @media (max-width: 880px) {
    width: 100%;
  }
`;

const ContainerTech = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border-top: 5px solid ${colors.tertiary};
  background: #fff;
  width: 30%;
  height: 457px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 20;

  padding: 1rem 1rem;
  gap: 1rem;

  @media (max-width: 880px) {
    width: 100%;
  }
`;

const ContainerTools = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border-top: 5px solid ${colors.primary200};
  background: #fff;
  width: 30%;
  height: 354px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 20;

  padding: 1rem 0;
  gap: 1rem;

  @media (max-width: 880px) {
    width: 100%;
  }
`;

const DotSkills = styled.img`
  position: absolute;
  z-index: 1;
`;

const Icon = styled.img``;

const Title = styled.h2`
  color: ${(props) => props.colortitle};
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
`;

const ContainerText = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

const Text = styled.p`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #406372;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;

  margin: 1rem 0;

  @media (max-width: 1230px) {
    font-size: 16px;
  }
`;

const Dot = styled.span`
  height: 8px;
  width: 8px;
  background-color: ${(props) => props.colortitle};
  border-radius: 50%;
  display: inline-block;
`;

const DotSkillsBottom = styled.img`
  position: absolute;
  z-index: 1;

  @media (max-width: 880px) {
    top: 50%;
    transform: translateY(-50%);
  }
`;

const DotSkillsRow = styled.img`
  display: none;
  position: absolute;
  z-index: 1;

  @media (max-width: 880px) {
    display: block;
    bottom: -10px;
    right: -40px;
  }
`;
