import styled from "styled-components";
import { colors } from "../utils/Colors";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { IoMdInformation, IoMdClose } from "react-icons/io";

//Import Swiper React
import { Swiper, SwiperSlide } from "swiper/react";
//Import Swiper Styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
// import "swiper/css/navigation";
import "../index.css";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import { useRef, useState } from "react";
import MobileProjects from "../Components/MobileProjects";

const Projects = () => {
  const [overlay, setOverlay] = useState("");
  const [activeSlide, setActiveSlide] = useState("");
  const sliderRef = useRef();

  /* Handle Click Slide */
  const handleClickSlide = (e, slide) => {
    setActiveSlide(slide);
    setOverlay(slide);
    // if (activeSlide === slide) {
    //   setOverlay(slide);
    // } else if (slide !== overlay) {
    //   setOverlay("");
    // }
  };

  /* Handle Show Overlay */
  const handleOverlay = (e, slide) => {
    e.stopPropagation();
    setOverlay(slide);
    if (overlay === slide) {
      setOverlay("");
    }
  };

  return (
    <MainContainer id="projects">
      <img
        src="/AboutWave.svg"
        alt="Wave About"
        style={{
          position: "absolute",
          bottom: "50px",
          left: 0,
        }}
      />
      <Container>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ContainerTitle>
            <H1>Projects</H1>
            <LineTitle></LineTitle>
          </ContainerTitle>

          <ContainerArrow>
            <ContainerArrowLeft
              onClick={() => {
                setOverlay("");
                setActiveSlide("");
                sliderRef.current?.slidePrev();
              }}
            >
              <AiOutlineArrowLeft />
            </ContainerArrowLeft>
            <ContainerArrowRight
              onClick={() => {
                setOverlay("");
                setActiveSlide("");
                sliderRef.current?.slideNext();
              }}
            >
              <AiOutlineArrowRight />
            </ContainerArrowRight>
          </ContainerArrow>
        </div>
        {/* SWIPER */}
        <Swiper
          onSwiper={(it) => (sliderRef.current = it)}
          slidesPerView={"auto"}
          spaceBetween={5}
          autoHeight={true}
          EffectCoverflow={true}
          pagination={{
            clickable: true,
          }}
          centeredSlides={true}
          loop={false}
          effect="coverflow"
          speed={1000}
          initialSlide={1}
          slideToClickedSlide={true}
          coverflowEffect={{
            rotate: -20,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: true,
          }}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
            disabledClass: "swiper-button-disabled",
          }}
          modules={[Pagination, EffectCoverflow, Navigation]}
          className="mySwiper"
        >
          <div class="swiper-wrapper">
            <SwiperSlide onClick={(e) => handleClickSlide(e, "ecommerce")}>
              <MoreInfoButton onClick={(e) => handleOverlay(e, "ecommerce")}>
                {overlay === "ecommerce" ? (
                  <IoMdClose size={32} />
                ) : (
                  <IoMdInformation />
                )}
              </MoreInfoButton>
              {overlay === "ecommerce" && (
                <Overlay>
                  <ContainerDescription style={{ fontSize: "20px" }}>
                    <p>
                      I created this full-stack e-commerce website with
                      <Bold> Bootstrap</Bold>, <Bold>Redux</Bold>, and{" "}
                      <Bold>SQL</Bold>. This was my first big project working
                      with these technologies.
                      <br />
                      <br />
                      The entire project is coded in <Bold>TypeScript</Bold>. I
                      utilized React with Vite for the frontend and employed{" "}
                      <Bold>Node.js</Bold> with <Bold>Express</Bold> for the
                      backend.
                      <br />
                      <br />I ensured that the website is{" "}
                      <Bold>fully responsive</Bold>, with various devices.
                    </p>
                  </ContainerDescription>
                  <ContainerLink>
                    <button
                      onClick={() =>
                        window.open(
                          "https://e-commerce-ts.onrender.com/",
                          "_blank"
                        )
                      }
                    >
                      Live
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          "https://github.com/FabienD0/Ecommerce",
                          "_blank"
                        )
                      }
                    >
                      Gitub
                    </button>
                  </ContainerLink>
                </Overlay>
              )}
              <img src="/cardEcommerce.png" alt="Ecommerce Card" />
            </SwiperSlide>
            <SwiperSlide onClick={(e) => handleClickSlide(e, "beatmi")}>
              <MoreInfoButton onClick={(e) => handleOverlay(e, "beatmi")}>
                {overlay === "beatmi" ? (
                  <IoMdClose size={32} />
                ) : (
                  <IoMdInformation />
                )}
              </MoreInfoButton>
              {overlay === "beatmi" && (
                <Overlay>
                  <ContainerDescription>
                    <p>
                      A dynamic music sequencer website that offers a{" "}
                      <Bold>unique</Bold> and <Bold>engaging </Bold>
                      platform to <Bold>create </Bold>, <Bold>share</Bold> and{" "}
                      <Bold>interact</Bold> with beats. <br /> <br /> With
                      BeatMi, you have the ability to <Bold>create</Bold> your
                      own beats, which includes <Bold>chords</Bold>,{" "}
                      <Bold>melody</Bold>, and <Bold>drum patterns</Bold>.<br />
                      <br />
                      This is my <Bold>biggest</Bold> and most{" "}
                      <Bold>significant</Bold> project.
                    </p>
                  </ContainerDescription>
                  <ContainerLink>
                    <button
                      onClick={() =>
                        window.open("https://www.beatmi.com", "_blank")
                      }
                    >
                      Live
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          "https://github.com/FabienD0/FinalProject-BeatMi",
                          "_blank"
                        )
                      }
                    >
                      Gitub
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          "https://vimeo.com/manage/videos/822198682",
                          "_blank"
                        )
                      }
                    >
                      Demo
                    </button>
                  </ContainerLink>
                </Overlay>
              )}
              <img src="/cardBeatmi.png" alt="BeatMi Card" />
            </SwiperSlide>
            <SwiperSlide onClick={(e) => handleClickSlide(e, "vingane")}>
              <MoreInfoButton onClick={(e) => handleOverlay(e, "vingane")}>
                {overlay === "vingane" ? (
                  <IoMdClose size={32} />
                ) : (
                  <IoMdInformation />
                )}
              </MoreInfoButton>
              {overlay === "vingane" && (
                <Overlay>
                  <ContainerDescription>
                    <p>
                      Mobile app that gathers all vegan wines available at SAQ.
                      <br />
                      <br />
                      Developed a <Bold>web scraper </Bold> application to
                      collect data, resulting in the creation of a structured
                      <Bold> database</Bold>.
                      <br />
                      <br />
                      The app was published on both the <Bold>
                        app store
                      </Bold>{" "}
                      and <Bold>iOS store</Bold>, gathering{" "}
                      <Bold>hundreds</Bold> of downloads and active users.
                    </p>
                  </ContainerDescription>
                  <ContainerLink>
                    <button
                      onClick={() =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.fabiend0.Vingane",
                          "_blank"
                        )
                      }
                    >
                      Android
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          "https://apps.apple.com/ca/app/vingane/id6450844596?platform=iphone",
                          "_blank"
                        )
                      }
                    >
                      iOS
                    </button>
                  </ContainerLink>
                </Overlay>
              )}
              <img src="/cardVingane.png" alt="Vingane Card" />
            </SwiperSlide>
            <SwiperSlide onClick={(e) => handleClickSlide(e, "todo")}>
              <MoreInfoButton onClick={(e) => handleOverlay(e, "todo")}>
                {overlay === "todo" ? (
                  <IoMdClose size={32} />
                ) : (
                  <IoMdInformation />
                )}
              </MoreInfoButton>
              {overlay === "todo" && (
                <Overlay>
                  <ContainerDescription>
                    <p>
                      This is my version of the famous <Bold>To-Do</Bold> list
                      application.
                      <br />
                      <br />
                      In addition to being my first <Bold>full-stack</Bold>{" "}
                      project, this was also my first time using{" "}
                      <Bold>Typescript</Bold> &<Bold> Next.js</Bold>.
                      <br />
                      <br />
                      For the backend, <Bold>mongoDB</Bold> is used when the
                      user logs in and everything is saved with{" "}
                      <Bold>Window local storage</Bold> if there is no user.
                    </p>
                  </ContainerDescription>
                  <ContainerLink>
                    <button
                      onClick={() =>
                        window.open(
                          "https://to-do-list-fabdev.vercel.app/",
                          "_blank"
                        )
                      }
                    >
                      Live
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          "https://github.com/FabienD0/To-Do-List",
                          "_blank"
                        )
                      }
                    >
                      Gitub
                    </button>
                  </ContainerLink>
                </Overlay>
              )}
              <img src="/Cardtodo.png" alt="To-Do Card" />
            </SwiperSlide>
            <SwiperSlide onClick={(e) => handleClickSlide(e, "budget")}>
              <MoreInfoButton onClick={(e) => handleOverlay(e, "budget")}>
                {overlay === "budget" ? (
                  <IoMdClose size={32} />
                ) : (
                  <IoMdInformation />
                )}
              </MoreInfoButton>
              {overlay === "budget" && (
                <Overlay>
                  <ContainerDescription style={{ fontSize: "19px" }}>
                    I couldn't find a basic application that met my needs. In
                    order to create the application I was seeking, I decided to
                    learn <Bold>React Native</Bold>.
                    <br />
                    <br />
                    You put your monthly income and fixed expenses. Then, you
                    choose how much you want to spend on groceries and gas each
                    week. Based on your budget, you will be able to determine
                    how much you have left over.
                    <br />
                    <br />A <Bold>custom hook</Bold> (usePersistedState) saves
                    everything with <Bold>AsyncStorage</Bold>.
                  </ContainerDescription>
                  <ContainerLink>
                    <button
                      onClick={() =>
                        window.open(
                          "https://github.com/FabienD0/Budget-MobileApp",
                          "_blank"
                        )
                      }
                    >
                      Github
                    </button>
                  </ContainerLink>
                </Overlay>
              )}
              <img src="/cardBudget.png" alt="Budget Card" />
            </SwiperSlide>
            <SwiperSlide onClick={(e) => handleClickSlide(e, "critter")}>
              <MoreInfoButton onClick={(e) => handleOverlay(e, "critter")}>
                {overlay === "critter" ? (
                  <IoMdClose size={32} />
                ) : (
                  <IoMdInformation />
                )}
              </MoreInfoButton>
              {overlay === "critter" && (
                <Overlay>
                  <ContainerDescription>
                    <p>
                      To improve my <Bold>React</Bold> skills, I had to build
                      the entire
                      <Bold> frontend</Bold> of a Twitter clone named "Critter"
                      a social network for cats.
                      <br />
                      <br />
                      The backend was already built. During this project, I was
                      faced with many challenges, facing them drove me to deeply
                      understand <Bold>React</Bold> and solve them.
                    </p>
                  </ContainerDescription>
                  <ContainerLink>
                    <button
                      onClick={() =>
                        window.open("https://vimeo.com/794596119", "_blank")
                      }
                    >
                      Demo
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          "https://github.com/FabienD0/Twitter-Clone",
                          "_blank"
                        )
                      }
                    >
                      Github
                    </button>
                  </ContainerLink>
                </Overlay>
              )}
              <img src="/cardCritter.png" alt="Critter Card" />
            </SwiperSlide>
            <SwiperSlide onClick={(e) => handleClickSlide(e, "pokemon")}>
              <MoreInfoButton onClick={(e) => handleOverlay(e, "pokemon")}>
                {overlay === "pokemon" ? (
                  <IoMdClose size={32} />
                ) : (
                  <IoMdInformation />
                )}
              </MoreInfoButton>
              {overlay === "pokemon" && (
                <Overlay>
                  <ContainerDescription>
                    <p>
                      This was my <Bold>first project</Bold>, a small{" "}
                      <Bold>HTML</Bold>, <Bold>CSS</Bold>, and{" "}
                      <Bold>Javascript</Bold> game.
                      <br />
                      <br />
                      Using keyboard keys, you must <Bold>catch</Bold> and{" "}
                      <Bold>avoid</Bold> multiple pokemon to increase your
                      score.
                    </p>
                  </ContainerDescription>
                  <ContainerLink>
                    <button
                      onClick={() =>
                        window.open(
                          "https://pokemonworldgame.netlify.app/",
                          "_blank"
                        )
                      }
                    >
                      Live
                    </button>
                    <button
                      onClick={() =>
                        window.open(
                          "https://github.com/FabienD0/Pokemon-World",
                          "_blank"
                        )
                      }
                    >
                      Github
                    </button>
                  </ContainerLink>
                </Overlay>
              )}
              <img src="/cardPokemonWorld.png" alt="Pokemon World Card" />
            </SwiperSlide>
          </div>
        </Swiper>
        {/* END SWIPER */}
        <MobileProjects />
      </Container>
      <img
        src="/AboutWave.svg"
        alt="Wave About"
        style={{
          position: "absolute",
          top: "300px",
          right: 0,
        }}
      />
    </MainContainer>
  );
};

export default Projects;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${colors.primary100};
  min-height: 55rem;

  @media only screen and (max-height: 1907px) and (pointer: coarse) {
    height: auto;
  }
`;

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  height: 100%;

  width: 70%;

  @media (max-width: 1150px) {
    width: 80%;
  }
`;

const ContainerTitle = styled.div`
  margin-top: 5rem;
  width: fit-content;
  position: relative;
  z-index: 2;

  @media (max-width: 880px) {
    margin-bottom: 2rem;
  }
`;

const H1 = styled.h1`
  color: #0f2c36;
  font-size: 42px;
  font-weight: 700;

  @media (max-width: 450px) {
    font-size: 34px;
  }
`;

const LineTitle = styled.div`
  position: absolute;
  bottom: 0;
  opacity: 65%;
  height: 20px;
  width: 105%;
  left: 50%;
  transform: translateX(-50%);

  background-color: ${colors.secondary};
  border-radius: 15px;

  z-index: -1;
`;

const ContainerArrow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
`;

const ContainerArrowLeft = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  color: ${colors.secondary};
  background-color: transparent;
  font-size: 28px;
  border: 2px solid ${colors.secondary};
  transition: all 0.3s;

  &:hover {
    border: 2px solid transparent;
    background-color: ${colors.secondary};
    color: ${colors.white};
    cursor: pointer;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

const ContainerArrowRight = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  color: ${colors.white};
  font-size: 28px;
  background-color: ${colors.secondary};
  border: 2px solid transparent;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

const Overlay = styled.div`
  position: absolute;
  background-color: black;
  opacity: 90%;
  border-radius: 15px;
  width: 100%;
  height: 100%;

  padding: 2rem;
`;

const MoreInfoButton = styled.button`
  all: unset;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: ${colors.primary};
  border: 1px solid transparent;
  font-size: 48px;
  color: ${colors.primary400};
  right: 20px;
  bottom: 30px;
  transition: all 0.3s;

  z-index: 22;

  opacity: 0.6;

  &:hover {
    cursor: pointer;
    border: 1px solid ${colors.primary400};
    opacity: 1;
  }
`;

const ContainerDescription = styled.div`
  color: ${colors.white};
  font-family: "Open Sans";
  text-align: left;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
`;

const Bold = styled.span`
  font-weight: 900;
  color: ${colors.primary100};
`;

const ContainerLink = styled.div`
  color: ${colors.primary};
  font-family: "Open Sans";
  text-align: left;
  font-size: 32px;
  font-weight: 900;
  line-height: normal;
  text-align: center;
  margin-top: 5rem;

  button {
    all: unset;
    border: 2px solid ${colors.primary};
    padding: 0.5rem 0.5rem;
    width: 8rem;
    border-radius: 15px;
    margin: 0 1rem;
    transition: all 0.3s;

    &:hover {
      color: ${colors.primary400};
      background-color: ${colors.primary};
      border: 2px solid transparent;
      cursor: pointer;
    }
  }
`;
