import styled from "styled-components";
import { colors } from "../utils/Colors";
import linkedinSvg from "../utils/linkedin.svg";
import gitSvg from "../utils/githubSvg.svg";
import { ReactSVG } from "react-svg";

const Footer = () => {
  return (
    <MainContainer>
      <Container>
        <p>© 2023 - Fabien Dénommée</p>
        <ContainerIcon>
          <a href="https://linkedin.com/in/fabien-developer" target="_blank">
            <LinkedinIcon src={linkedinSvg} />
          </a>
          <a href="https://github.com/FabienD0" target="_blank">
            <GitIcon src={gitSvg} />
          </a>
        </ContainerIcon>
        {/* <ContainerLink>
          <a href="#">Home</a>
          <a href="#">About</a>
          <a href="#">Skills</a>
          <a href="#">Projects</a>
        </ContainerLink> */}
      </Container>
    </MainContainer>
  );
};

export default Footer;

const MainContainer = styled.footer`
  width: 100%;
  background-color: ${colors.primary300};
  min-height: 5rem;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colors.primary100};

  z-index: 123;

  width: 90%;

  @media (max-width: 1150px) {
    width: 80%;
  }

  p {
    color: ${colors.primary100};
    font-weight: bold;
    font-size: 18px;

    @media (max-width: 450px) {
      font-size: 12px;
    }
  }
`;

const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

const LinkedinIcon = styled(ReactSVG)`
  width: 42px;
  height: auto;
  transition: all 0.3s;
  fill: hsla(197, 10%, 50%, 1);
  fill: ${colors.primary100};

  &:hover {
    fill: ${colors.primary200};
    cursor: pointer;
  }
`;

const GitIcon = styled(ReactSVG)`
  width: 42px;
  height: auto;
  transition: all 0.3s;
  fill: ${colors.primary100};

  &:hover {
    fill: ${colors.primary200};
    cursor: pointer;
  }
`;

const ContainerLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  a {
    all: unset;
    color: ${colors.primary100};
    font-weight: bold;
    font-size: 18px;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      color: ${colors.primary200};
    }
  }
`;
